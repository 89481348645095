import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import sizes from "../shared/sizes";

const Nav = styled.header`
  padding: 2em 3em;

  ${sizes.phone} {
    padding: 2em 0em;
  }
  ${sizes.tablet} {
    padding: 2em 1em;
  }
`;

const RightButtons = styled.div`
  float: right;
  ${sizes.phone} {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 0.5em;
  }
`;

const LeftButtons = styled.div`
  float: left;
  font-weight: bold;
  font-size: 16pt;
  font-family: "Advent Pro", sans-serif;
  text-transform: uppercase;
  ${sizes.phone} {
    display: block;
    text-align: center;
    width: 100%;
  }
`;

const NavButton = styled.div`
  display: inline-block;
  margin: 0 1em;
`;

const Header = () => {
  return (
    <Nav>
      <LeftButtons>
        <NavButton>
          <Link className="rainbow" to="/">
            Daniel Merrill
          </Link>
        </NavButton>
      </LeftButtons>
      <RightButtons>
        <NavButton>
          <Link className="rainbow" to="/career">
            Career
          </Link>
        </NavButton>
        <NavButton>
          <Link className="rainbow" to="/contact">
            Contact
          </Link>
        </NavButton>
      </RightButtons>
    </Nav>
  );
};

export default Header;
