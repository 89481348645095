import sizes from "../shared/sizes";
import colors from "../shared/colors";
import styled from "styled-components";

export const Container = styled.div`
  & > * {
    max-width: 800px;
  }

  ${sizes.phone} {
    padding: 2.6em;
  }
  ${sizes.tablet} {
    padding: 2.6em 5em;
  }
  ${sizes.computer} {
    padding: 2.6em 13em;
  }

  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  text-align: left;
`;

const Section = styled(Container)`
  color: ${colors.lighterGray};
  background-color: ${(props) =>
    props.theme === "semidark" ? colors.darkGray : colors.black};
  text-align: ${(props) => props.textAlign || "center"};
`;

export default Section;
