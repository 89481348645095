import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import colors from "../shared/colors";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialButton = styled.div`
  padding: 4px;
  border-radius: 50%;
  background: ${colors.darkGray};
  margin: 0 1em;
  font-size: 1em;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  color: white;

  &:hover {
    background: ${(props) => props.color};
    color: white;
  }
`;

const Social = () => (
  <Wrapper>
    <OutboundLink href="https://github.com/dmerrill6" target="_blank">
      <SocialButton id="github" color={colors.gray}>
        <FontAwesomeIcon icon={faGithub} />
      </SocialButton>
    </OutboundLink>
    <OutboundLink href="https://twitter.com/dan1elmerrill" target="_blank">
      <SocialButton id="twitter" color="#1DA1F2">
        <FontAwesomeIcon icon={faTwitter} />
      </SocialButton>
    </OutboundLink>
    <OutboundLink
      href="https://www.linkedin.com/in/daniel-merrill/"
      target="_blank"
    >
      <SocialButton id="linkedin" color="#2867B2">
        <FontAwesomeIcon icon={faLinkedin} />
      </SocialButton>
    </OutboundLink>
  </Wrapper>
);

export default Social;
