const colors = {
  lightGray: "#b4bac0",
  lighterGray: "#c3c8ce",
  gray: "#808080",
  darkGray: "#343a40",
  black: "#202020",
  white: "#fff",
  yellow: "#f5c841",
  cyan: "#3abbcf",
  magenta: "#cd68cb",
  green: "#68be49",
  transparent: "rgba(0,0,0,0)",
  lightBlue: "#d4f7f7",
};

export default colors;
