import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import Helmet from "react-helmet";
import Header from "./header";
import colors from "../shared/colors";
import watercolor from "../images/watercolor.jpg";
import Social from "../components/social";
import Section from "../components/section";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: 'Nunito Sans', -apple-system, Roboto, sans-serif, serif;
    & p{
      letter-spacing: 0.03em;
      line-height: 1.5;
    }
  }

  a {
    color: ${colors.white};
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    letter-spacing: 0.04em;
  }
  a.rainbow:hover, a.rainbow:focus, .rainbow a:hover, .rainbow a:focus {
    text-decoration: none;
    background: url(${watercolor}) repeat 10% 0%;
    background-size: auto;
    color: ${colors.cyan};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip:text;
    background-clip:text;
    padding:0;
    margin:0;

    animation-name: linkAnim;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes linkAnim {
    from {
      background-position: 5% 0%;
    }
    to {
      background-position: 100% 100%;
    }
  }
`;

const Content = styled.div`
  /* display: flex;
  min-height: 100vh;
  height: 100%;
  flex-direction: column; */
`;

const Copywright = styled.div`
  text-align: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Footer = styled(Section)`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Children = styled.div`
  flex: 1 0 auto;
  min-height: calc(100vh - 230px);
`;

const Layout = ({ children, title }) => (
  <React.Fragment>
    <Header />
    <GlobalStyle />
    <Helmet>
      <title>
        Daniel Merrill - Software Engineer {title ? `| ${title}` : ""}
      </title>
      <meta
        name="description"
        content="Personal site & portfolio of Daniel Merrill"
      />
    </Helmet>
    <Content>
      <Children>{children}</Children>
      <Footer theme="semidark">
        <Social />
        <Copywright>
          <p>©{new Date().getFullYear()} Daniel Merrill</p>
        </Copywright>
      </Footer>
    </Content>
  </React.Fragment>
);

export default Layout;
